<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <table
      class="table_custom_button"
      :style="style"
    >
      <tbody>
        <tr>
          <td
            class="custom_button_value_display"
            v-dompurify-html="value"
          />
        </tr>
        <tr>
          <td>
            <v-btn
              :class="`no-clip ${classes}`"
              :disabled="disabled"
              type="button"
              :style="buttonStyle"
              data-content-button-action="custom_button"
              small
              @click="onClick"
            >
              {{ buttonLabel }}
            </v-btn>
          </td>
        </tr>
      </tbody>
    </table>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeCustomButton',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    style() {
      let style = false
      const outlineColor = this.dataViewer.outline_color
      if (outlineColor) {
        style = `outline: 1px solid ${outlineColor};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    },
    buttonStyle() {
      let style = ''
      const settings = this.cell.settings
      if (settings.custom_button_width) {
        style += `width: ${settings.custom_button_width};`
      }
      return style
    },
    buttonLabel() {
      const settings = this.cell.settings
      const builder = this.data.ChecklistBuilder
      const label = builder.getLocalization(settings, 'custom_button_label')
      return label || ''
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const value = builder.getCellValue(index, this.cell, this.clone, {})
      return value.custom_button_value_display || ''
    }
  },
  methods: {
    onClick(event) {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const dataEditor = this.cell
      const settings = dataEditor.settings || {}
      const onBeforeClick = builder.getSetting('ON_CUSTOM_BUTTON_CLICK')
      const previousValue = builder.getCellValue(index, dataEditor, this.clone, {})

      const execClick = () => {
        const actions = builder.getSetting('CUSTOM_BUTTON_ACTIONS') || {}
        const action = actions[settings.custom_button_action] || {}
        if (action.clicked !== false) {
          builder.evalValues({
            index: index,
            dataEditor: dataEditor,
            dataViewer: this.dataViewer,
            cloneIndex: this.clone,
            encode: false,
            saveCallback: undefined,
            ignoreAutoSaveSetting: false,
            value: 'clicked',
            previousValue: previousValue
          })
        }
        if (typeof action.action === 'function') {
          action.action(index, dataEditor, this.dataViewer, (value, callback) => {
            if (action.clicked === false || value === 'clicked') {
              builder.evalValues({
                index: index,
                dataEditor: dataEditor,
                dataViewer: this.dataViewer,
                cloneIndex: this.clone,
                encode: false,
                saveCallback: callback,
                ignoreAutoSaveSetting: false,
                value: value,
                previousValue: previousValue
              })
            }
          })
        }
      }

      if (
        typeof onBeforeClick === 'function' &&
        settings.custom_button_on_click
      ) {
        onBeforeClick(execClick, index, dataEditor)
      } else {
        execClick()
      }
    }
  }
}
</script>

<style>
  .checklist-viewer .checklist-content .element-content .table_custom_button {
    border-spacing: 0px;
  }

  .checklist-viewer .checklist-content .element-content .table_custom_button
  .custom_button_value_display > table {
    width: auto;
    margin: 0 auto;
    text-align: left;
    border: 1px solid #999;
      border-collapse: collapse;
    border-spacing: 0px;
    padding-bottom: 10px;
  }

  .checklist-viewer .checklist-content .element-content .table_custom_button
  .custom_button_value_display > table > tbody > tr:nth-child(odd) {
      background-color: #fff;
  }

  .checklist-viewer .checklist-content .element-content .table_custom_button
  .custom_button_value_display > table > tbody > tr:nth-child(even) {
      background-color: #eee;
  }

  .checklist-viewer .checklist-content .element-content .table_custom_button
  .custom_button_value_display > table > tbody > tr > td:first-child {
    vertical-align: top;
    border-right: 1px solid #eee;
    padding: 5px 30px 5px 5px;
  }

  .checklist-viewer .checklist-content .element-content .table_custom_button
  .custom_button_value_display > table > tbody > tr > td:nth-child(n+1) {
    vertical-align: top;
    padding: 5px;
  }
</style>
